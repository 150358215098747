<template>
  <div class="call-container">
    <videsk-webrtc
      @hangup="conditionalHangup"
      ref="webrtc"
      sidebar
    />
    <div class="call-menu-container">
      <div
        class="call-menu-item"
        @click="toggleInfoCall"
      >
        <a-tooltip>
          <template slot="title">
            {{ $t('call.tooltip.info') }}
          </template>
          <a-icon type="info-circle" />
        </a-tooltip>
      </div>
      <div
        class="call-menu-item"
        v-show="containsAgentForm"
        @click="toggleAgentForm"
      >
        <a-tooltip>
          <template slot="title">
            {{ $t('call.menu.form') }}
          </template>
          <a-icon type="file-done" />
        </a-tooltip>
      </div>
      <div
        class="call-menu-item chat"
        @click="toggleChat"
        :unread="unreadMessages"
      >
        <a-tooltip>
          <template slot="title">
            {{ $t('call.tooltip.chat') }}
          </template>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16px"
            fill="#3F536E"
            viewBox="0 0 640 512"
          ><path d="M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2 0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.3-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9l0 0 0 0-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z" /></svg>
        </a-tooltip>
      </div>
      <div
        class="call-menu-item"
        @click="toggleFiles"
        v-show="$store.getters['global/settings'].fileShare.enabled"
      >
        <a-tooltip>
          <template slot="title">
            {{ $t('call.tooltip.sharefiles') }}
          </template>
          <a-icon type="file-add" />
        </a-tooltip>
      </div>
      <div
        class="call-menu-item"
        @click="toggleCapture"
        v-show="captureEnabled"
      >
        <a-tooltip>
          <template slot="title">
            {{ $t('call.tooltip.capture') }}
          </template>
          <a-icon type="camera" />
        </a-tooltip>
      </div>
      <div
        class="call-menu-item"
        @click="transfer.show = true"
        v-if="!isMeeting"
      >
        <a-tooltip>
          <template slot="title">
            {{ $t('call.tooltip.transfer') }}
          </template>
          <a-icon type="swap" />
        </a-tooltip>
      </div>
      <div
        class="call-menu-item"
        @click="invite.show = true"
        v-if="!isMeeting"
      >
        <a-tooltip>
          <template slot="title">
            {{ $t('call.tooltip.invite') }}
          </template>
          <a-icon type="user-add" />
        </a-tooltip>
      </div>
      <div
       class="call-menu-item"
       @click="showConfirmKick">

       <a-tooltip>
         <template slot="title">
           {{ $t('call.tooltip.kick') }}
         </template>
         <a-icon type="export" />
       </a-tooltip>
      </div>
      <div
       class="call-menu-item"
       v-if="appsEnabled"
       @click="showApps">

       <a-tooltip>
         <template slot="title">
           {{ $t('call.tooltip.apps') }}
         </template>
         <a-icon type="appstore" />
       </a-tooltip>
      </div>
      <div
        class="call-menu-item"
        @click="toggleRecording"
        v-show="showRecordingButton"
      >
        <a-tooltip>
          <template slot="title">
            {{ recordingTooltip }}
          </template>
          <div
            class="rec-icon"
            :class="{ 'active': isRecording }"
          >
            rec
          </div>
        </a-tooltip>
      </div>
    </div>
    <div
      class="call-body-container"
      style="height: 100%;"
    >
      <agent-form v-show="viewName === 'agent-form'"/>
      <call-info v-show="viewName === 'info'"/>
      <file-share v-show="viewName === 'fileshare'"/>
      <chat-view v-show="viewName === 'chat'"/>
      <capture-view v-show="viewName === 'capture'"/>
      <custom-apps v-show="viewName === 'custom-apps'" />
    </div>
    <ModalTransfer
      :show="transfer.show"
      :loading="transfer.loading"
      :confirm="ConfirmTransfer"
      :cancel="CancelTransfer"
    />
    <ModalInvite
      :show="invite.show"
      :loading="invite.loading"
      :confirm="ConfirmInvite"
      :cancel="CancelInvite"
    />
  </div>
</template>

<script>
import Agamotto from '@videsk/agamotto';
import { v4 as uuid } from 'uuid';
import LocalRecorder from '@/plugins/recorder/local-recorder';
import ModalTransfer from '@/components/call/transfer-modal.vue';
import ModalInvite from '@/components/call/invite-modal.vue';

import callInfo from './info-call.vue';
import fileShare from './fileshare.vue';
import chatView from './chat.vue';
import agentForm from './agent-form.vue';
import captureView from './capture.vue';
import apps from './apps/index.vue';

export default {
  components: {
    ModalTransfer,
    ModalInvite,
    callInfo,
    fileShare,
    chatView,
    agentForm,
    captureView,
    'custom-apps': apps,
  },
  data: () => ({
    viewName: 'info',
    onHold: false,
    transfer: {
      show: false,
      loading: false,
    },
    transfer_fail: { show: false },
    invite: {
      show: false,
      loading: false,
    },
    visible: false,
    recorder: null,
    screen: null,
    recording: false,
    self_mic: null,
    mixer: null,
    stream_recorded: null,
    webrtc: null,
    unreadMessages: 0,
    cloudRecorder: null,
    isRecording: false,
    controller: new AbortController(),
  }),
  computed: {
    socket() {
      return this.$store.getters['global/socket'];
    },
    events() {
      return {
        leave: this.end,
      };
    },
    isMeeting() {
      return this.$store.getters['calendar/onMeeting'];
    },
    transferredSegmentSuccess() {
      return this.$store.getters['global/transfer_successful'];
    },
    transferSegmentFail() {
      return this.$store.getters['global/transferSegmentFail'];
    },
    transferAgentFail() {
      return this.$store.getters['global/transferAgentFail'];
    },
    InvitationSuccessful() {
      return this.$store.getters['global/invitation_successful'];
    },
    InvitationFailed() {
      return this.$store.getters['global/invitation_failed'];
    },
    onHoldIcon() {
      return (this.onHold) ? 'play-circle' : 'pause';
    },
    recordingTooltip() {
      const key = this.recording || this.cloudRecorder ? 'stop' : 'rec';
      return this.$t(`call.tooltip.${key}`);
    },
    invitedAgentHangup() {
      return this.$store.getters['global/invitedAgentHangup'];
    },
    hostnameSignaling() {
      return this.$signalingOptions.websocket.hostname;
    },
    hostnameRecorder() {
      return this.$agamottoOptions.websocket.hostname;
    },
    recordingToken() {
      return this.$store.getters['call/recordingToken'];
    },
    recordingsSettings() {
      return this.$store.getters['call/recordingsSettings'];
    },
    appsEnabled() {
      const { apps = [] } = this.$store.getters['global/settings'] || {};
      return apps.length > 0;
    },
    recordingLocalSettings() {
      const { recorder = {} } = this.$store.getters['global/settings'] || {};
      return recorder;
    },
    showRecordingButton() {
      return this.recordingsSettings.enabled && !this.recordingsSettings.automaticRecord;
    },
    containsAgentForm() {
      return !!this.$store.getters['call/agentForm'];
    },
    captureEnabled() {
      return this.$store.getters['global/settings'].capture?.enabled;
    },
    mandatoryTags() {
      return this.$store.getters['call/mandatoryTags'] || this.$store.getters['calendar/mandatoryTags'];
    },
  },
  watch: {
    transferredSegmentSuccess(obj) {
      const { status, reason } = obj;
      if (!status) return;
      this.$Notify.success({ message: reason });
      this.transfer.show = false;
      this.transfer.loading = false;
      this.$store.commit('global/transfer_successful', { status: false, reason: '' });
    },
    transferSegmentFail(obj) {
      const { status, reason } = obj;
      if (!status) return;
      this.transfer.loading = false;
      this.$Notify.warning({ message: reason });
      this.$store.commit('global/transferSegmentFail', { status: false, reason: '' });
    },
    transferAgentFail(obj) {
      const { status, reason } = obj;
      if (!status) return;
      this.transfer.loading = false;
      this.$Notify.warning({ message: reason });
      this.$store.commit('global/transferAgentFail', { status: false, reason: '' });
    },
    InvitationSuccessful(obj) {
      const { status, reason } = obj;
      if (!status) return;
      this.invite.loading = false;
      this.invite.show = false;
      this.$Notify.success({ message: reason });
      this.$store.commit('global/invitation_successful', { status: false, reason: '' });
    },
    InvitationFailed(obj) {
      const { status, reason } = obj;
      if (!status) return;
      this.invite.loading = false;
      this.invite.show = false;
      this.$Notify.warning({ message: reason });
      this.$store.commit('global/invitation_failed', { status: false, reason: '' });
    },
    invitedAgentHangup(status) {
      if (!status) return;
      this.$Notify.info({ message: this.$t('call.invite.hangup'), duration: 7000 });
      this.$store.commit('global/invitedAgentHangup', false);
    },
  },
  async mounted() {
    document.addEventListener('hangup-view', () => this.end(), { once: true });
    this.$store.commit('components/view', 'info');
    this.$store.commit('components/view', 'chat');
    await new Promise(resolve => setTimeout(resolve, 500));
    if (this.containsAgentForm) this.$store.commit('components/view', 'agent-form');
    await new Promise(resolve => setTimeout(resolve, 500));
    this.$store.commit('components/view', 'info');
    this.loadGlobalListeners();
    this.initWebRTC();
  },
  methods: {
    toggleCloudRecording() {
      if (!this.recorder) return;
      if (this.recorder.recording) this.recorder.pause();
      else if (this.recorder.paused) this.recorder.resume();
      else this.recorder.start();
    },
    toggleLocalRecording() {
      if (!this.localRecorder) return;
      if (this.localRecorder.recording) this.localRecorder.pause();
      else if (this.localRecorder.paused) this.localRecorder.resume();
      else this.localRecorder.start();
    },
    async conditionalHangup() {
      const agentFormIsValid = await this.verifyAgentForm();
      if (!agentFormIsValid) return this.$Notify.warning({
        message: this.$t('call.form.mandatoryTitle'),
        description: this.$t('call.form.mandatoryDescription')
      });
      if (!this.isMeeting) this.$store.dispatch('call/end');
      else this.$store.dispatch('calendar/endMeeting');
    },
    async end() {
      if (this.recorder) this.recorder.stop();
      else if (this.localRecorder) this.localRecorder.stop();
      if (this.webrtc) {
        this.webrtc.destroy(false);
        this.$store.commit('global/webrtcInstance', null);
      }
      await this.$virtualBackground?.pause();
      if (!this.controller.signal.aborted) this.controller.abort();
    },
    ToggleOnHold() {
      this.$refs.videocall.toggleMedia();
    },
    ConfirmTransfer() {
      this.transfer.loading = true;
      this.$store.dispatch('call/transfer');
      this.$mixpanel.track('Transfer');
    },
    CancelTransfer() {
      this.transfer.loading = false;
      this.transfer.show = false;
      this.$mixpanel.track('Cancel transfer');
    },
    ConfirmInvite() {
      this.invite.loading = true;
      this.$store.dispatch('call/invite');
      this.$mixpanel.track('Invite');
    },
    CancelInvite() {
      this.invite.loading = false;
      this.invite.show = false;
      this.$mixpanel.track('Cancel invite');
    },
    getVirtualBackgroundValue() {
      const { virtualBackground: userVirtualBackground } = this.$store.getters['global/userSettings'];
      const { virtualBackground } = this.$store.getters['global/settings'];
      if (!userVirtualBackground.background || userVirtualBackground.background === 'blur') return 7;
      const result = virtualBackground.images.find(image => image._id === userVirtualBackground.background);
      return result && result.url;
    },
    async virtualBackgroundStream(camera) {
      const userSettings = this.$store.getters['global/userSettings'];
      const backgroundValue = this.getVirtualBackgroundValue();
      const property = userSettings.virtualBackground.background.length === 24 && backgroundValue ? 'image' : 'blur';
      this.$virtualBackground[property] = backgroundValue;
      if (!this.$virtualBackground?.input?.active) await this.$virtualBackground?.render(camera);
      return this.$virtualBackground?.start();
    },
    async initWebRTC() {
      try {
        // this.webrtc = new this.$WebRTC(this.$refs.webrtc, null, { websocket: { hostname: `wss://${this.hostname}` } });
        this.webrtc = new this.$WebRTC(this.$refs.webrtc, null, { websocket: { hostname: this.hostnameSignaling } });
        this.$store.commit('global/webrtcInstance', this.webrtc);
        const { enabled, type, audioBitsPerSeconds, videoBitsPerSeconds, maxBytes, automaticRecord, audio, video } = this.recordingsSettings;
        const options = {
          hostname: this.hostnameRecorder,
          interval: 1000,
          maxlength: maxBytes,
          audioBitsPerSeconds,
          videoBitsPerSeconds,
          instantRecord: automaticRecord,
          audio,
          video,
        };
        if (enabled && type === 'cloud') this.recorder = new Agamotto(this.recordingToken, options);
        else if (enabled && type === 'local') this.localRecorder = new LocalRecorder();

        if (this.recorder) this.loadCloudRecorderListeners();
        else if (this.localRecorder) this.loadLocalRecordingListeners();

        this.webrtc.addEventListener('peer:new', (event) => {
          const { peer } = event.detail;
          if (this.localRecorder) this.localRecorder.attachPeer(peer);
          else if (this.recorder) this.recorder.attachPeer(peer);
        });
        if (this.localRecorder || this.recorder) (this.localRecorder || this.recorder).attach(this.$store.getters['global/globalCameraStream'], uuid(), { speaker: 'agent' });
        if (this.localRecorder && automaticRecord) this.localRecorder.start().catch(console.error);

        (this.localRecorder || this.recorder)?.addEventListener('recording', () => this.socket.emit('exchange:customer', { event: 'recording:status', data: true }), { signal: this.controller.signal });
        (this.localRecorder || this.recorder)?.addEventListener('paused', () => this.socket.emit('exchange:customer', { event: 'recording:status', data: false }), { signal: this.controller.signal });
        (this.localRecorder || this.recorder)?.addEventListener('stopped', () => this.socket.emit('exchange:customer', { event: 'recording:status', data: false }), { signal: this.controller.signal });

        this.webrtc.addEventListener('new-message', event => {
          if (this.viewName !== 'chat') this.unreadMessages += 1;
          const customEvent = new CustomEvent('message:received', { detail: event.detail });
          document.dispatchEvent(customEvent);
        });
        const settings = this.$store.getters['global/settings'];
        const userSettings = this.$store.getters['global/userSettings'];
        const virtualBackgroundEnabled = settings?.virtualBackground?.enabled && userSettings?.virtualBackground?.enabled;

        const camera = this.$store.getters['global/globalCameraStream'];
        const outputStream = virtualBackgroundEnabled ? await this.virtualBackgroundStream(camera) : camera;
        if (this.recorder && outputStream?.id !== camera?.id) this.recorder.block(outputStream);
        await this.webrtc.create(this.$store.getters['call/signalingToken'], null, outputStream);
        const bigScreen = this.webrtc.node.query('#big-screen').query('video');
        this.$pip.setWindowLeaveListener(bigScreen);
        this.$pip.setLeaveListener(bigScreen);
      } catch (error) {
        console.error(error);
      }
    },
    loadCloudRecorderListeners() {
      if (!this.recorder) return console.error('The cloud recorder instance is not loaded.');
      const webrtc = document.querySelector('videsk-webrtc');
      this.recorder.addEventListener('recording', () => {
        this.socket.emit('recording');
        if (webrtc) webrtc.recording = true;
        this.isRecording = true;
      });
      this.recorder.addEventListener('paused', () => {
        if (webrtc) webrtc.recording = false;
        this.isRecording = false;
      });
      this.recorder.addEventListener('stopped', () => {
        if (webrtc) webrtc.recording = false;
        this.isRecording = false;
      });
    },
    loadLocalRecordingListeners() {
      if (!this.localRecorder) return console.error('The local recorder instance is not loaded.');
      const webrtc = document.querySelector('videsk-webrtc');
      this.localRecorder.addEventListener('stopped', () => {
        this.localRecorder.download();
        this.localRecorder = undefined;
        if (webrtc) webrtc.recording = false;
        this.isRecording = false;
      });
      this.localRecorder.addEventListener('recording', () => {
        if (webrtc) webrtc.recording = true;
        this.isRecording = true;
      });
      this.localRecorder.addEventListener('paused', () => {
        if (webrtc) webrtc.recording = false;
        this.isRecording = false;
      });
    },
    loadGlobalListeners() {
      document.addEventListener('new-message', event => {
        const { localName } = event.target;
        if (localName.toLowerCase() !== 'videsk-chat') return;
        const { message, remote } = event.detail;
        this.$refs.webrtc.addMessage(message, !remote, false);
        this.webrtc.sendMessage(message, !remote, false);
      });
      document.addEventListener('fullscreen', event => {
        const { status } = event.detail;
        const { webrtc } = this.$refs;
        if (!webrtc) return;
        webrtc.classList.toggle('fullscreen', status);
        if (webrtc.chat) this.$store.commit('components/view', 'chat');
        if (webrtc.chat && !status) webrtc.chat = false;
      });
    },
    toggleFiles() {
      this.viewName ='fileshare';
    },
    toggleCapture() {
      this.viewName= 'capture';
    },
    toggleAgentForm() {
      this.viewName = 'agent-form';
    },
    toggleInfoCall() {
      this.viewName = 'info';
    },
    toggleChat() {
      this.unreadMessages = 0;
      this.viewName = 'chat';
    },
    toggleRecording() {
      if (!this.recordingsSettings?.enabled) return;
      const { type } = this.recordingsSettings ?? {};
      if (type === 'local') return this.localRecorder?.toggle();
      else if (type === 'cloud') this.toggleCloudRecording();
    },
    showApps() {
      this.viewName = 'custom-apps';
    },
    async verifyAgentForm() {
      if (!this.containsAgentForm) return true;
      const agentForm = this.$store.getters['call/formInstance'];
      this.toggleAgentForm();
      await new Promise(resolve => setTimeout(resolve, 200));
      const { valid } = agentForm.submit();
      return valid;
    },
    showConfirmKick() {
      this.$Modal.confirm({
        title: this.$t('call.kickModal.title'),
        content: this.$t('call.kickModal.description'),
        okText: this.$t('words.ok'),
        cancelText: this.$t('words.cancel'),
      })
        .then(() => this.socket.emit('kick'))
        .catch(() => {});
    },
  },
};
</script>

<style>
.videsk-webrtc-container {
  width: 100%;
  height: 100%;
  border: none;
}
.call-container {
  width: 100%;
  height: calc(100% - 50px);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 999;
}

.call-menu-item.chat {
  position: relative;
}

.call-menu-item.chat:not([unread="0"]):before {
  content: attr(unread);
  display: flex;
  width: 1rem;
  height: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
  background: red;
  color: white;
  position: absolute;
  top: 12px;
  right: -6px;
  animation: bounce 0.5s infinite;
}

videsk-webrtc {
  width: 100%;
  height: 250px;
  max-height: 250px;
  overflow: hidden;
}

videsk-webrtc:not(.fullscreen)::part(button-chat) {
  display: none;
}

.call-menu-container {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid #c7d2e0;
}

.call-menu-item {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1;
  transition: all 0.5s ease;
}

.call-menu-item:hover {
  opacity: 0.6;
}

.call-menu-item i {
  font-size: 1.4em;
}

.call-body-container {
  width: 100%;
  min-height: calc(100% - (250px + 50px));
  max-height: calc(100% - (250px + 50px));
  overflow: auto;
}

.call-menu-item.soon {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>

<style>
.webrtc-ui__buttons-container {
  height: 50px !important;
}

.webrtc-ui__button-webrtc {
  width: 35px !important;
  height: 35px !important;
}

.webrtc-ui__hide_participants.hide {
  display: block !important;
}

.rec-icon {
  padding: 1px 5px;
  border-radius: 3px;
  color: white;
  background: #c7c7c7;
  font-size: 0.7em;
  font-weight: bold;
  text-transform: uppercase;
  user-select: none;
}

.rec-icon.active {
  background: #d9363e;
  animation: recording 1.2s linear infinite;
}

@keyframes recording {
  0% { opacity: 1; }
  100% { opacity: 50%; }
}
</style>

<style>
.mirror-mode-button {
  left: 0;
  top: 50% !important;
  right: auto;
  flex-direction: row-reverse;
  opacity: 0.8;
  transform: translateX(-76%);
}

.mirror-mode-button:not(:hover):before {
  margin-right: 5px;
  margin-left: 10px;
}

.mirror-mode-button:hover {
  transform: translateX(0px);
  flex-direction: row;
  opacity: 1;
}

@keyframes bounce {
   0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
   40% {transform: translateY(-30px);}
   60% {transform: translateY(-15px);}
}
</style>
